<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.agent.agentListing')" :onSearch="onSearch">
                <template v-slot:headerLeft>
                    <button type="button" name="add-agent" v-if="showAddButton" class="btn btn-success" @click="onAddAgent">
                        {{ $t('pages.agent.addAgent') }}
                    </button>
                </template>
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('pages.dashboard.agents') }}</label>
                                    <div class="col-sm-9 input-group">
                                        <multiselect :options="agentList"
                                                     v-model="selectedAgents"
                                                     :loading="isLoadingAgents"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     @search-change="asyncFindAgency"
                                                     :internal-search="false"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>                              
                            </CCol>
                            <CCol md="6">
                                <CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')" v-model="phoneNumber"
                                        @keypress="$func.onlyNumbers" horizontal>
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">
                                        {{ $t("pages.agent.ownerName") }}
                                    </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="ownerList"
                                                     v-model="selectedOwner"
                                                     :loading="isLoadingOwner"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     :internal-search="false"
                                                     @search-change="asyncFindOwner"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="profileId">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6">                                
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="allAgents"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
                        <template #footer v-if="allAgents != null && allAgents.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="8">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allAgents == null ? 0: allAgents.length}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #manager_text="{item}">
                            <td class="py-2">
                                <label v-text="showManagerName(item.manager)" />
                            </td>
                        </template>
                        <template #user_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton v-if="showEditDeletedButton" color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEditAgent(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                                <CButton v-if="showEditDeletedButton" color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDeleteAgent(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDetailAgent(item, index)">
                                    {{ $t('common.detail') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>                  
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'
    
    export default {
        name: 'Tables',
        data() {
            return {
                agentList: [],
                selectedAgents: null,
                isLoadingAgents: false,
                phoneNumber: '',
                ownerList: [],
                selectedOwner: null,
                isLoadingOwner: false,
                saleManagerList: [],
                selectedSaleManager: null,
                isLoadingSaleManager: false,
                itemsPerPage: 10,
                warningModal: false,
                showAddButton: false,
                showEditDeletedButton: false,
                fields: [
                    { key: 'name', label: i18n.t('pages.agent.agentName') },                    
                    { key: 'ownerName', label: i18n.t('pages.agent.ownerName') },
                    { key: 'manager_text', label: i18n.t('pages.manager.salesManager') },                    
                    { key: 'phone', label: i18n.t('common.phone') },
                    { key: 'address', label: i18n.t('common.address') },
                    {
                        key: 'user_detail_actions',
                        label:  i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: { Confirmation, ListForm },
        computed: {          
            ...mapState('agent', ['isLoading', 'allAgents', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('agent', ['searchAgents']),
            onDeleteAgent(item) {
                this.$refs.confirmation.show(i18n.t('pages.agent.confirmDelete'), async () => {
                    //console.log('Delete Agent', item);
                    var retVal = await this.$store.dispatch("agent/deleteAgent", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
            onEditAgent(item) {
                this.$router.push('/agents/edit/' + item.id);
            },
            onDetailAgent(item) {
                this.$router.push('/agents/detail/' + item.id);
            },
            onSearch() {
                let _ownerIds = [],_saleManagerIds = [], _agentIds = [];
                if (this.selectedOwner != null && this.selectedOwner.length > 0)
                    _ownerIds = this.selectedOwner.map(item => item.profileId);
               

                if (this.selectedAgents != null && this.selectedAgents.length > 0)
                    _agentIds = this.selectedAgents.map(item => item.id);
                
                this.searchAgents({ pageNumber: 0, agentIds: _agentIds, phone: this.phoneNumber, ownerIds: _ownerIds, saleManagerIds: _saleManagerIds  });
            },           
            onAddAgent() {
                this.$router.push('/agents/add');
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('agentItemsPerPage', item)
            },
            showManagerName(manager) {
                if (typeof (manager) === 'undefined')
                    return '';
                return manager;
            },
            async asyncFindOwner(query, id) {

                if (query.length > 2) {
                    this.isLoadingOwner = true;
                    var result = await this.$accountService.searchByName(query);
                    this.isLoadingOwner = false;
                    this.ownerList = result;
                }
            },
            async asyncFindSaleManager(query, id) {
                if (query.length > 1) {
                    this.isLoadingSaleManager = true;
                    var result = await this.$salesmanService.searchByName(query);
                    this.isLoadingSaleManager = false;
                    this.saleManagerList = result;
                }
            },
            async asyncFindAgency(query, id) {
                if (query.length > 2) {
                    this.isLoadingAgents = true;
                    var result = await this.$agentService.searchByName(query);
                    this.isLoadingAgents = false;
                    this.agentList = result;
                }
            },
        },
        created() {
            let role = this.$store.state.auth.user.role;
           
            if (role === 'admin' || role === 'sale') {
                this.showEditDeletedButton = true;
                this.showAddButton = true;                
            }                
           
            this.onSearch();

            let agentItemsPerPage = parseInt(localStorage.getItem('agentItemsPerPage'));
            if (isNaN(agentItemsPerPage))
                agentItemsPerPage = 10;
            this.itemsPerPage = agentItemsPerPage;           
        }     
    }
</script>